<template>
<div id="DataTable">

<v-container fluid style="margin-top: -200px">
<!-- table card -->
<v-card class="pb-5 mx-auto elevation-5 dataTableBg">
<v-card-title class="px-5 mx-0">
  <v-text-field v-model="search" append-icon="search" label="Search" 
  single-line hide-details solo ></v-text-field>
</v-card-title>
 <!-- table properties  -->
<v-data-table v-model="selected" :headers="tableData.headers" :search="search"
 :loading = "loadingFlag" loading-text="Filling Up Realtime Data From Cloud..."
 :items="tableData.Data" :single-select="true" height="300" 
 show-select class="elevation-2 mx-5" multi-sort>
<!-- show if no data is present -->
<template v-slot:no-data>
  <h2 class="mt-5">No Records!</h2>
</template>
</v-data-table>
</v-card>
<!-- table card ends -->
</v-container>

</div>
</template>

<script>
export default {
    name: 'datatablevue',

    created(){                
        setTimeout(()=>{ this.timer = true  }, 5000);
    },

    data(){return{
        
        selected: [],
        search: '',
        timer: false,

    }},

    computed: {

        loadingFlag(){
            if(this.tableData.Data.length == 0){
                if(this.timer){return false;}
                return true;
            }else{
                return false;
            }
        },

        SelectedData() {
            const selectedRow = this.selected[0];
            return selectedRow ? selectedRow : null;
        },
        
    },

    watch: {
        SelectedData(newvalue) {
            this.SelectedData != null ? this.ShowDetails(this.SelectedData) : null;
        },
    },

    methods: {
        ShowDetails(data){            
            // check if the passed data is student's data
            if(data.studentID != null){
                this.$router.push('/studentdetails/' + data.studentID);
            }else if(data.uid != null){
                this.$router.push('/userdetails/' + data.uid);
            }else if(data.slug != null){
                this.$router.push('/postdetails/' + data.slug);
            }else if(data.eventslug != null){
                this.$router.push('/eventdetails/' + data.eventslug);
            }else if(data.courseslug != null){
                this.$router.push('/coursedetails/' + data.courseslug);
            }else if(data.awardslug != null){
                this.$router.push('/awarddetails/' + data.awardslug);
            }
        }
    },

    props: ['tableData'],
}
</script>

<style lang="scss" scoped>

</style>